main{
  background:#fff; 
  margin-top:52px;
}
.mat-form-field-underline{
    display: none !important;
}
.mat-form-field-flex{
    border: 1px solid #ced4da !important;
    height: 36px !important;
    border-radius: 6px !important;
    padding-bottom: 4px !important
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0;
    margin-top: 0px;
    margin-left: 5px;
    margin-right: 5px;
}
.mat-chip-list-wrapper input.mat-input-element{
    margin-top: 8px !important;
}

.custom-table1 tr:nth-child(even){
    background: #fff !important;
}
.custom-table1 tr:nth-child(odd){
    background: #f2f9ff !important;
}
.custom-table1 thead th{
   background: #b2d7f4 !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #8ac4f4 !important;
    color: #1b6baa !important;
}
.custom-table1 td{
     border-radius: 0 !important;
}
.navbar{
    height:52px;
}
.d-flex-gap{
    gap:8px;
}
.hr{
    width:99%; 
    margin-top:6px; 
    margin-bottom: 0; 
    color:#000
}
.mat-paginator-container .mat-paginator-page-size{
    align-items: center !important;
}
.mat-paginator-container .mat-form-field-appearance-legacy .mat-form-field-infix{
    margin-top: 5px !important;
}
.mat-paginator-container .mat-paginator-range-label {
    margin: 5px 32px 0 24px !important;
}

.wt__btnPrimary{
    background-color: #0E85DB;
    border-color: #0E85DB !important;
    border-radius: 6px;
    color:#fff;
}
.wt__btnPrimary:hover,
.wt__btnPrimary:focus{
    background-color: #0058c7 !important;
    border-color: #0058c7;
    color:#fff;
}
.btn-outline-primary{
    color: #0E85DB !important
}

/* HEADER : BEGIN */
.header{
    background-color: #fff;
    border-bottom: 1px solid #E8ECF1;
    margin: 0 -15px 30px -15px;
    padding: 5px 25px 0 25px;
    box-shadow: 2px -2px 9px rgba(0,0,0,0.2);
}
.header .header-title{
   margin-bottom:10px;
  font-size: 26px;
}
.header .breadcrumb {
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 13px;
}
.header-right{
    display: flex;
    flex-direction: column;
    align-items: end;
}
/* HEADER : END */






/* CUSTOM FORM : BEGIN */
.custom-form{
    padding-left: 25px;
    padding-right: 25px;
}
.custom-form h3{
  color: #0b85db;
    border-left: 3px solid #0b85db;
    padding-bottom: 0 !important;
    padding-left: 5px;
    font-size: 18px;
    line-height: 24px;
}
.custom-form .form-label,
.custom-form .col-form-label {
    text-transform: uppercase;
    font-weight: 600 !important;
}
.custom-form input.form-control,
.custom-form .dropdown-btn,
.custom-form .form-select,
.custom-form .mat-form-field-flex {
    border: none !important;
    border: 1px solid #e5e5e5 !important;
    padding: 12px 10px;
    border-radius: 5px;
    color:#a8a8a8
}
.custom-form .mat-form-field-flex{
    height:47px !important;
    padding-top: 7px !important;
}
.custom-form input.form-control::placeholder{
    color:#a8a8a8 !important
}
.add-row-btn{
    max-width: 190px;
}
.example-chip-list[_ngcontent-mkm-c3] {
    width: 100%;
    border: 1px solid #dadada;
    padding: 11px 10px;
    border-radius: 6px;
}
/* CUSTOM FORM : END  */










